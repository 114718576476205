import { LOCALES } from '../constants';

export default {
  [LOCALES.RUSSIAN]: {
    MainContText2: `Контакты`,
    Main: `Главная`,
    Living: `Проживание`,
    Cafe: `Кафе`,
    Gallery: `Галерея`,
    Service: `Дополнительные услуги`,

    /*Для футера*/
    Place: `Ивано-Франковская область с. Яблуница, урочище Дил, 280Д`,
    CottegesAndNum: `выбирай`,
    Recomend: `НАС РЕКОМЕНДУЮТ`,
    Cottege: `Коттедж`,
    Luxe: `Двухкомнатный номер "Люкс"`,
    ThreePlace: `Трехместный номер с балконом`,
    TwoPlace: `Двухместный номер с балконом`,
    WithMan: `Двухместный мансардный`,


    /*Для головної сторінки*/
    FirstSlide: `Наслаждайся`,
    FirstSlide2: `Отдыхом`,
    FirstSlide3: `Вместе с нами`,
    SecondSlide: `Почувствуй`,
    SecondSlide2: `Полноценный отдых`,
    SecondSlide3: `Вместе с нами`,
    ThirdSlide: `Место`,
    ThirdSlide2: `Для отдыха`,
    ThirdSlide3: `Вместе с нами`,
    Booking1: `Искренне благодарен высокой оценке нашей работы `,
    Booking2: `с Вашей стороны в 2020 году. Обещаем в дальнейшем `,
    Booking3: `продолжать обеспечивать комфортный `,
    Booking4: `европейский отдых в сочетании  `,
    Booking5: `c украинским гостеприимством. `,
    Booking6: `С уважением, персонал мини-отеля «Альпийский»`,
    Details: `Подробнее...`,
    BenefitsMain1: `Детская площадка`,
    BenefitsMain2: `БЕСПЛАТНЫЙ WI-FI`,
    BenefitsMain3: `Cтоянка под видеонаблюдением`,
    BenefitsMain4: `видеонаблюдением`,
    BenefitsMain5: `Сауна`,
    BenefitsMain6: `Бугельный подъемник`,
    BenefitsMain8: `Беседки и мангалы`,
    BenefitsMain10: `Услуги партнеров`,
    BenefitsMain12: `На территории отеля обустроена детская площадка`,
    BenefitsMain22: `Где Ваш ребенок может весело провести время`,
    BenefitsMain32: `На территории отеля есть доступ к скоростному интернету Wi-Fi`,
    BenefitsMain42: `На территории и отеля обустроена стоянка с видеонаблюдением`,
    BenefitsMain52: `Ваш автомобиль будет находиться под полной безопасностью`,
    BenefitsMain62: `На территории отеля есть сауна с бассейном`,
    BenefitsMain72: `Бугель оснащен рядом с отелем`,
    BenefitsMain82: `На территории отеля "Альписький" есть замечательная беседка с
    мангалом`,
    AboutUsMain: '   Мини-отель "Альпийский" расположен в живописной части Карпат, в селе Яблуница. Гостиничный комплекс включает в себя трехэтажный корпус и три двухуровневые апартаменты с отдельными входами. На территории отеля есть детская площадка, беседки и мангалы, автостоянка. Рядом с отелем расположена невысокая трасса с бугельным подъемником, а к горнолыжного курорта "Буковель" можно добраться на автомобиле за 15 минут. Рядом также расположен еще один горнолыжный курорт - "Драгобрат".',
    AboutUsMainTitle: 'Приглашаем',
    /*Проживання*/
    Numbers: `Номера`,
    Cotteges: `Коттеджи`,
    Book: `Забронировать`,
    Watch: `Посмотреть`,
    Price1: `от 2 800,00 грн`,
    Price2: `от 1 200,00 грн`,
    Price3: `от 1 650,00 грн`,
    Price4: `от 1 400,00 грн`,
    Price5: `от 5 000,00 грн`,
    Guess: `гостей`,
    Sleep: `спальня`,
    Sleep2: `спальни`,
    Sleepq: `гостиная`,
    /*Номери*/
    Amenities: `Удобства`,
    Fen: `Фен`,
    Tele: `Телевизор`,
    Holod: `Холодильник`,
    Balcon: `Балкон`,
    Electro: `Оборудованная кухня`,
    Kamin: `Камин`,
    Toelet: `Санузел`,
    Toelet1: `Санвузла`,
    Kik: `Кухня`,
    Stars: `Оценки от`,
    Review: `Отзывы наших клиентов`,
    Opis1: `Просторный двухэтажный коттедж площадью 70 м2 рассчитан на 7 человек, имеет отдельный вход. На втором этаже коттеджа размещены две спальни с отдельными санузлами (душ), двуспальными кроватями и телевизорами со спутниковым телевидением. На первом этаже расположена гостиная с диваном, раскладным креслом, камином, телевизором и санузлом с душевой кабинкой. Также на первом этаже есть кухня, оборудованная электроплитой, холодильником, электрочайником, кофейным аппаратом, тостером, кухонными принадлежностями. Во всех санузлах есть фены и туалетные принадлежности. Коттедж оборудован средствами очистки воды. WiFi есть по всей территории отеля.`,
    Opis2: `Двухкомнатный номер «люкс» площадью 35 м2 расположены на первом этаже трехэтажного корпуса.
     В номере есть спальня с двуспальной кроватью и гостиная с диваном и креслом.
     В номере также есть балкон, свой санузел с душевой кабиной (имеется фен и средства личной гигиены), шкаф-гардероб, холодильник, телевизор с плоским экраном и спутниковыми каналами.`,
    Opis3: `Двухместный номер эконом-класса площадью 12 м2 расположен на мансардном этаже трехэтажного корпуса. В комнате есть двуспальная кровать с прикроватными тумбами, вешалка для одежды с полками, маленький холодильник, телевизор с плоским экраном и спутниковыми каналами. В комнате есть отдельная ванная комната (душ) с феном и туалетными принадлежностями.`,
    Opis4: `Трехместные номера с отдельным балконом площадью 16 м2 расположены на первом и втором этажах трехэтажного корпуса. В этой категории номеров все номера с одной двуспальной кроватью и диваном. В каждом номере есть свой санузел с душевой кабиной (имеется фен и средства личной гигиены), шкаф-гардероб, маленький холодильник, телевизор с плоским экраном и спутниковыми каналами.`,
    Opis5: `Двухместные номера с отдельным балконом площадью 16 м2 расположены на первом и втором этажах трехэтажного корпуса. В этой категории номеров есть номера с одной двуспальной или двумя односпальными кроватями. В каждом номере есть свой санузел с душевой кабиной (имеется фен и средства личной гигиены), шкаф-гардероб, маленький холодильник, телевизор с плоским экраном и спутниковыми каналами.`,


    /*Послуги*/
    SaunaM: `Сауна с контрастным бассейном`,
    Sauna1: `Отдохнуть и оздоровиться гости отеля «Альпийский» могут в сауне с контрастным бассейном, вода в котором проходит фильтрацию и очистку по новейшим технологиям.`,
    Sauna2: `В стоимость посещения сауны входит пользование полотенцами, простынями, одноразовые тапочки. Посетителям сауны также могут предложить травяной чай и веники. Вместимость сауны 6 человек.`,
    TennisM: `Теннисный стол`,
    Tennis: `Для тех, кто любит активный отдых, у нас есть теннисный стол. Для гостей отеля пользование им бесплатное.`,
    AltanM: `Беседки и мангалы`,
    Altan: `Гости отеля могут бесплатно пользоваться беседками и мангалами с видом на Говерлу и Петрос.`,
    CafeM: `Домашнее кафе`,
    Cafe2: `На территории отеля работает кафе, где можно заказать блюда украинской кухни.`,
    Other228: `Услуги, которые предоставляются партнерами отеля
  `,
    Other227: `Прокат лыжного снаряжения`,
    Other226: `Прокат велосипедов и квадроциклов`,
    Other225: `Катание на джипах по горам`,
    Other224: `Чаны`,
    Other223: `Рибалка`,
    Other222: `Рафтинг`,
    Other221: `Трансфер легковым авто или микроавтобусом`,
    Details: `Подробнее...`,
    Glava1Book: `  После бронирования администратор отеля связывается с гостем и сообщает
реквизиты для осуществления авансового платежа. Бронирование считается
подтвержденным только после получения предоплаты. При незаезде аванс не
возвращается.`,
    Glava1BookMain: `І. Бронирование:`,
    Glava2Book: `  - проживания;
  - уборка: полная уборка проводится перед заездом новых потребителей,
  текущее и смена полотенец - один раз в три дня;
  - смена постельного белья (осуществляется один раз в пять дней);
  - круглосуточное пользование услугой Wi-Fi;
  - пользование беседкой и мангалами;
  - пользование детской площадкой;
  - парковка видеонаблюдением.`,
    Glava2BookMain: `ІІ. Перечень основных услуг, входящих в цены номера:`,
    Glava3Book: `
  - час заезда - с 15:00;

  - час выезда - до 11:00.

  При проживании в гостинице менее суток отель взимает плату за одни сутки
независимо от времени размещения. В случае заезда в отель с 0:00 до 11:00 (ранний
заезд) или отъезда с 15:00 до 24:00 (поздний выезд) с потребителя взимается
дополнительная плата в размере 50% стоимости проживания в номере за сутки
согласно действующему тарифу.`,
    Glava3BookMain: `ІІІ. Отелем установлены следующие час заезда и выезда:`,
    Glava4Book: `  При размещении детей в возрасте до шести лет в одном номере с родителями, без
предоставления отдельного места, плата за проживание детей не взимается (кроме услуг,
не входящих в стоимость номера).`,
    Glava4BookMain: `IV. Размещение на дополнительных местах:`,
    GlavaBookMain: `Условия бронирования и проживания`,

  },
};
