import { LOCALES } from "../constants";

export default {
  [LOCALES.UKRAINE]: {
    /*Часто використовуються*/
    MainContText2: `Контакти`,
    Main: `Головна`,
    Living: `Проживання`,
    Cafe: `Кафе`,
    Gallery: `Галерея`,
    Service: `Додаткові послуги`,

    /*Для футера*/
    Place: `Івано-Франківська область с. Яблуниця, урочище Діл, 280Д`,
    CottegesAndNum: `Обирай`,
    Recomend: `НАС РЕКОМЕНДУЮТЬ`,
    Cottege: `Котедж`,
    Luxe: `Двокімнатний номер "Люкс"`,
    ThreePlace: `Тримісний номер з балконом`,
    TwoPlace: `Двомісний номер з балконом`,
    WithMan: `Двомісний мансардний`,

    /*Для головної сторінки*/
    FirstSlide: `Насолоджуйся`,
    FirstSlide2: `Відпочинком`,
    FirstSlide3: `Разом з нами`,
    SecondSlide: `Відчуй`,
    SecondSlide2: `Повноцінний відпочинок`,
    SecondSlide3: `Разом з нами`,
    ThirdSlide: `Чудове місце`,
    ThirdSlide2: `Для відпочинку`,
    ThirdSlide3: `Разом з нами`,
    Booking1: `Щиро вдячний високій оцінці нашої праці з Вашого боку у `,
    Booking2: `2020 році. Обіцяємо надалі продовжувати`,
    Booking3: `забезпечувати комфортний європейський відпочинок в`,
    Booking4: ` поєднанні з українською гостинністю. А також `,
    Booking5: `дивувати Вас приємними нововведеннями.`,
    Booking6: `З повагою, персонал міні-готелю «Альпійський»`,
    BenefitsMain1: `Дитячий майданчик`,
    BenefitsMain2: `БЕЗКОШТОВНИЙ WI-FI`,
    BenefitsMain3: `Cтоянка під відеонаглядом`,
    BenefitsMain4: `відеонаглядом`,
    BenefitsMain5: `Сауна`,
    BenefitsMain6: `Бугельний підйомник`,
    BenefitsMain8: `Альтанки і мангали`,
    BenefitsMain10: `Послуги 
                     партнерів`,
    BenefitsMain12: `На території готелю облаштовано дитячий майданчик`,
    BenefitsMain22: `Де Ваша дитина може весело провести час`,
    BenefitsMain32: `На території готелю є доступ до швидкісного інтернету Wi-Fi`,
    BenefitsMain42: `На територіїя готелю облаштована стоянка з відеонаглядом`,
    BenefitsMain52: `Ваш автомобіль знаходитиметься під цілковитою безпекою`,
    BenefitsMain62: `На території готелю є сауна з басейном`,
    BenefitsMain72: `Бугель облаштований поряд з готелем`,
    BenefitsMain82: `На території готелю “Альпіський” є чудова альтанка з
 мангалом`,
    AboutUsMain:
      '   Міні-готель "Альпійський" розташований у мальовничій частині Карпат, у селі Яблуниця, 960 м над рівнем моря. Готель поєднує в собі строгий альпійський стиль та неповторність карпатської природи. Готельний комплекс включає в себе триповерховий корпус та три дворівневі апартаменти з окремими входами. З терас готелю відкривається дивовижний вигляд на гори Говерла і Петрос. На території готелю є дитяча площадка, альтанки та мангали, автостоянка. Поряд з готелем розташована невисока траса з бугельним підйомником, а до гірсько-лижного курорту "Буковель" можна добратись на автомобілі за 15 хвилин. Поряд також розташований ще один гірсько-лижний курорт - "Драгобрат". ',
    AboutUsMainTitle: 'Запрошуємо',
    /*Проживання*/
    Numbers: `Номера`,
    Cotteges: `Котеджі`,
    Book: `Забронювати`,
    Watch: `Переглянути`,
    Price1: `від 2 800,00 грн`,
    Price2: `від 1 200,00 грн`,
    Price3: `від 1 650,00 грн`,
    Price4: `від 1 400,00 грн`,
    Price5: `від 5 000,00 грн`,
    Guess: `гостей`,
    Sleep: `спальня`,
    Sleep2: `спальні`,
    Sleepq: `вітальня`,

    /*Номери*/
    Amenities: `Зручності`,
    Fen: `Фен`,
    Tele: `Телевізор`,
    Holod: `Холодильник`,
    Balcon: `Балкон`,
    Pich: `Мікрохвильова піч`,
    Electro: `Обладнана кухня`,
    Toelet: `Санвузол`,
    Toelet1: `Санвузла`,
    Kik: `Кухня`,
    Stars: `Оцінки від`,
    Review: `Відгуки наших клієнтів`,
    Opis1: `Просторий двоповерховий котедж площею 70 м2 розрахований на 7 чоловік, має окремий вхід. На другому поверсі котеджу розміщені дві спальні з окремими санвузлами (душ), двоспальними ліжками і телевізорами з супутниковим телебаченням. На першому поверсі розташована вітальня з диваном, розкладним кріслом, каміном, телевізором та санвузлом з душовою кабінкою. Також на першому поверсі є кухня, обладнана електроплитою, холодильником, електрочайником, кавовим апаратом, тостером, кухонним приладдям. У всіх санвузлах є фени та туалетні приладдя. Котедж обладнаний засобами очищення води. WiFi є по всій території готелю.`,
    Opis2: `Двокімнатний номер «люкс» площею 35 м2 розташовані на першому поверсі трьохповерхового корпусу. 
  У номері є спальня з широким двоспальним ліжком та вітальня з розкладним диваном та кріслом. 
  В номері також є балкон, свій санвузол з душовою кабіною (наявний фен та засоби особистої гігієни), шафа-гардероб, холодильник, телевізор з плоским екраном і супутниковими каналами.`,
    Opis3: `Двомісний номер економ-класу площею 12 м2 розташований на мансардному поверсі трьохповерхового корпусу. У кімнаті є двоспальне ліжко з тумбами, вішак для одягу з поличками, маленький холодильник, телевізор з плоским екраном і супутниковими каналами. У кімнаті є окрема ванна кімната (душ) з феном і туалетним приладдям.`,
    Opis4: `Тримісні номера з окремим балконом площею 16 м2 розташовані на першому та другому поверхах трьохповерхового корпусу. У цій категорії номерів всі номера з одним двоспальним ліжком та диваном. В кожному номері є свій санвузол з душовою кабіною (наявний фен та засоби особистої гігієни), шафа-гардероб, маленький холодильник, телевізор з плоским екраном і супутниковими каналами.`,
    Opis5: `Двомісні номера з окремим балконом площею 16 м2 розташовані на першому та другому поверхах трьохповерхового корпусу. У цій категорії номерів є номера з одним двоспальним або двома односпальними ліжками. В кожному номері є свій санвузол з душовою кабіною (наявний фен та засоби особистої гігієни), шафа-гардероб, маленький холодильник, телевізор з плоским екраном і супутниковими каналами.`,

    /*Послуги*/
    SaunaM: `Сауна з контрасним басейном`,
    Sauna1: `Відпочити та оздоровитись гості готелю «Альпійський» можуть у сауні з контрастним басейном, вода в якому проходить фільтрацію та очищення за новітніми технологіями.  `,
    Sauna2: `  У вартість відвідування сауни входить користування рушниками, простирадлами, одноразові тапочки. Відвідувачам сауни також можуть запропонувати трав’яний чай та віники. Місткість сауни 6 осіб.`,

    TennisM: `Тенісний стіл`,
    Tennis: `Для тих, хто полюбляє активний відпочинок, у нас є тенісний стіл. Для гостей готелю користування ним безкоштовне.`,
    AltanM: `Альтанки і мангали`,
    Altan: `Гості готелю можуть безкоштовно користуватися альтанками та мангалами з виглядом на Говерлу і Петрос.`,
    CafeM: `Домашнє кафе`,
    Cafe2: `На території готелю працює кафе, де ви можете замовити страви української кухні.`,
    Other228: `Послуги, які надаються партнерами готелю`,
    Other227: `Прокат лижного спорядження`,
    Other226: `Прокат велосипедів та квадроциклів`,
    Other225: `Катання на джипах по горах`,
    Other224: `Чани`,
    Other223: `Рибалка`,
    Other222: `Рафтинг`,
    Other221: `Трансфер легковим авто або бусом`,
    Details: `Детальніше...`,
    Kamin: `Камін`,
    Glava1Book: `  Після бронювання адміністратор готелю зв’язується з гостем та повідомляє
реквізити для здійснення авансового платежу. Бронювання вважається
підтвердженим лише після отримання передоплати. При незаїзді аванс не
повертається.`,
    Glava1BookMain: `І. Бронювання:`,
    Glava2Book: `  - проживання;
  - прибирання: повне прибирання проводиться перед заїздом нових споживачів,
  поточне та заміна рушників - один раз в три дня;
  - зміна постільної білизни (здійснюється один раз в п’ять днів);
  - цілодобове користування послугою Wi-Fi;
  - користування альтанкою та мангалами;
  - користування дитячою площадкою;
  - паркування під відеонаглядом.`,
    Glava2BookMain: `ІІ. Перелік основних послуг, що входять до ціни номеру:`,
    Glava3Book: `
  - година заїзду – з 15:00;

  - година виїзду – до 11:00.

  При проживанні в готелі менше однієї доби готель стягує плату за одну добу
незалежно від часу розміщення. У разі заїзду в готель з 0:00 до 11:00 (ранній
заїзд) або виїзду з готелю з 15:00 до 24:00 (пізній виїзд) із споживача стягується
додаткова плата у розмірі 50% вартості проживання в номері за одну добу
згідно з діючим тарифом.`,
    Glava3BookMain: `ІІІ. Готелем установлені наступні година заїзду та виїзду:`,
    Glava4Book: `  При розміщенні дітей віком до шести років в одному номері з батьками, без
надання окремого місця, плата за проживання дітей не стягується (крім послуг,
що не входять у вартість номеру).`,
    Glava4BookMain: `IV. Розміщення на додаткових місцях:`,
    GlavaBookMain: `Умови бронювання та проживання`,

  },
};
