import { LOCALES } from '../constants';

export default {
  [LOCALES.ENGLISH]: {
    /*Часто використовуються*/
    MainContText2: `Contacts`,
    Main: `Main`,
    Living: `Residence`,
    Cafe: `Cafe`,
    Gallery: `Gallery`,
    Service: `Additional Services`,


    /*Для футера*/
    Place: `Ivano-Frankivsk region with. Yablunytsia, Dil tract, 280D`,
    CottegesAndNum: `choose`,
    Recomend: `WE ARE RECOMMENDED`,
    Cottege: `Cottage`,
    Luxe: `Two-Room Suite `,
    ThreePlace: `Triple room with balcony`,
    TwoPlace: `Double room with balcony`,
    WithMan: `Double attic`,


    /*Для головної сторінки*/
    FirstSlide: `Enjoy`,
    FirstSlide2: `Your vacation`,
    FirstSlide3: `With us`,
    SecondSlide: `Feel`,
    SecondSlide2: `Complete rest`,
    SecondSlide3: `With us`,
    ThirdSlide: `Great place`,
    ThirdSlide2: `To relax`,
    ThirdSlide3: `With us`,
    Booking1: `I am sincerely grateful for the high appreciation of our work  `,
    Booking2: `on your part in 2020. We promise to continue`,
    Booking3: `to provide a comfortable European holiday in`,
    Booking4: ` combined with Ukrainian hospitality. And `,
    Booking5: `also to surprise you with pleasant innovations.`,
    Booking6: `Sincerely, the staff of the mini-hotel "Alphotel"`,
    Details: `Read more...`,
    BenefitsMain1: `Playground`,
    BenefitsMain2: `FREE WI-FI`,
    BenefitsMain3: `Parking under video surveillance`,
    BenefitsMain4: `VIDEO SURVEILLANCE`,
    BenefitsMain5: `Sauna`,
    BenefitsMain6: `Tow lift`,
    BenefitsMain8: `Garden with barbecue`,
    BenefitsMain10: `Partner services`,
    BenefitsMain12: `There is a children’s playground on site`,
    BenefitsMain22: `Where your child can have fun`,
    BenefitsMain32: `Wi-Fi high-speed internet access is available on site`,
    BenefitsMain42: `There is a parking lot with video surveillance on site`,
    BenefitsMain52: `Your car will be completely safe`,
    BenefitsMain62: `The hotel has a sauna with a swimming pool`,
    BenefitsMain72: `The ski lift is located next to the hotel`,
    BenefitsMain82: `On the territory of the hotel "Alphotel" there is a wonderful gazebo with
 brazier`,
    AboutUsMain: '   Mini-hotel “Alpijkij” is located in a picturesque part of the Carpathians in the village Yablunitsa. The hotel combines rigorous alpine style and uniqueness of Carpathian nature. The hotel complex includes a Three-body and three-level apartments with separate entrances. The hotel has a children’s playground, gazebos and barbecues, parking. In winter, tourists often devote his free afternoon skating on snowy mountain roads. Depending on the skills and abilities you can choose for yourself the most comfortable route.',
    AboutUsMainTitle: 'We invite you',

    /*Проживання*/
    Numbers: `Rooms`,
    Cotteges: `Cottages`,
    Book: `Reserve`,
    Watch: `Review`,
    Price1: `from UAH 2 800,00`,
    Price2: `from UAH 1 200,00`,
    Price3: `from UAH 1 650,00`,
    Price4: `from UAH 1 400,00`,
    Price5: `from UAH 5 000,00`,
    Guess: `guests`,
    Sleep: `bedroom`,
    Sleep2: `bedrooms`,
    Sleepq: `living room`,


    /*Номери*/
    Amenities: `Amenities`,
    Fen: `Hair dryer`,
    Tele: `TV`,
    Holod: `Refrigerator`,
    Balcon: `Balcony`,
    Pich: `Microwave`,
    Electro: `Equipped kitchen`,
    Kamin: `Fireplace`,
    Toelet: `Bathroom`,
    Toelet1: `Bathrooms`,
    Kik: `Kitchen`,
    Stars: `Estimates from`,
    Review: `Feedback from our customers`,
    Opis1: `Spacious two-storey cottage with an area of ​​70 m2 is designed for 7 people, has a separate entrance. On the second floor of the cottage there are two bedrooms with separate bathrooms (showers), double beds and TVs with satellite TV. On the ground floor there is a living room with a sofa, a folding chair, a fireplace, a TV and a bathroom with a shower. Also on the ground floor there is a kitchen equipped with an electric stove, refrigerator, electric kettle, coffee maker, toaster, kitchen utensils. All bathrooms have hairdryers and toiletries. The cottage is equipped with water treatment. WiFi is available throughout the hotel.`,
    Opis2: `Two-room suite with an area of ​​35 m2 is located on the ground floor of a three-storey building.
  Features a bedroom with a wide double bed and a living room with a sofa bed and armchair.
  The room also has a balcony, a private bathroom with shower (hairdryer and toiletries), a wardrobe, a refrigerator, a flat-screen TV with satellite channels.`,
    Opis3: `Economy double room with an area of ​​12 m2 is located on the attic floor of a three-storey building. The room has a double bed with bedside tables, a clothes hanger with shelves, a small refrigerator, a flat-screen TV with satellite channels. The room has a private bathroom (shower) with a hairdryer and toiletries.`,
    Opis4: `Triple rooms with a private balcony of 16 m2 are located on the first and second floors of a three-storey building. In this room category all rooms have one double bed and a sofa. Each room has a private bathroom with a shower (hairdryer and toiletries), a wardrobe, a small fridge, a flat-screen TV with satellite channels.`,
    Opis5: `Double rooms with a private balcony of 16 m2 are located on the first and second floors of a three-storey building. This room category has rooms with one double or two single beds. Each room has a private bathroom with a shower (hairdryer and toiletries), a wardrobe, a small fridge, a flat-screen TV with satellite channels.`,


    /*Послуги*/
    SaunaM: `Sauna with contrasting pool`,
    Sauna1: `Guests of the Alpine Hotel can relax and rejuvenate in the sauna with a contrasting pool, where the water is filtered and purified using the latest technology. `,
    Sauna2: `The cost of visiting the sauna includes the use of towels, sheets, disposable slippers. Guests of the sauna can also enjoy herbal tea and brooms. Sauna capacity 6 people.`,
    TennisM: `Tennis table`,
    Tennis: `For those who enjoy active recreation, we have a tennis table. It is free for hotel guests.`,
    AltanM: `Gazebos and barbecues`,
    Altan: `Guests have free use of gazebos and barbecues overlooking Hoverla and Petros.`,
    CafeM: `Home cafe`,
    Cafe2: `There is a café on site where you can order Ukrainian cuisine.`,
    Other228: `Services provided by hotel partners`,
    Other227: `Ski equipment rental`,
    Other226: `Bicycle and ATV rental`,
    Other225: `Jeep riding in the mountains`,
    Other224: `Vat`,
    Other223: `Fishing`,
    Other222: `Rafting`,
    Other221: `Transfer by car or bus`,
    Details: `Details...`,
    Glava1Book: `  After booking, the hotel manager will contact the guest and inform you
details for making an advance payment. Booking is considered
confirmed only after receiving the prepayment. In case of no-show no advance
returns.`,
    Glava1BookMain: `І. Booking:`,
    Glava2Book: `  - residence;
  - cleaning: complete cleaning is carried out before the arrival of new consumers,
current and towel change - once every three days;
  - change of bed linen (once every five days);
  - 24-hour use of Wi-Fi service;
  - use of gazebo and barbecue;
  - use of playground;
  - parking under video surveillance.`,
    Glava2BookMain: `ІІ. List of basic services included in the room price:`,
    Glava3Book: `
  - arrival time - from 15:00;

  - departure time - until 11:00.

  For stays of less than one day, the hotel will charge one day
regardless of the time of placement. In case of arrival at the hotel from 0:00 to 11:00 (early
check-in) or check-out from 15:00 to 24:00 (late check-out)
extra charge of 50% of the room stay per night
according to the current tariff.`,
    Glava3BookMain: `ІІІ. The hotel has the following hours of arrival and departure:`,
    Glava4Book: `  When placing children under the age of six in the same room with parents, without
provision of a separate place, children are not charged (except for services,
not included in the room rate).`,
    Glava4BookMain: `IV. Accommodation in additional places:`,
    GlavaBookMain: `Booking and accommodation conditions`,

  },
};
